<template>
  <div class="summary-data">
    <LoadingContainer v-if="loading" />
    <template v-else>
      <a-empty v-if="!data || !data.count" />
      <div v-else class="detail">
        <h3>{{ data.count }}</h3>
        <p>
          {{
            $t('summary-desc', {
              from: $filters.date(data.from, { format: $variables.dateFormat }),
            })
          }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import LoadingContainer from '@/components/LoadingContainer';

export default {
  name: 'DataSummary',
  components: { LoadingContainer },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    loading: {
      type: Boolean,
    },
    config: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-data {
  height: 100%;
}

.detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 64px;
    line-height: 81px;
    color: #008189;
  }
}
</style>
